<template>
	<div class="flex justify-center">
		<component v-if="providerComponent" :is="providerComponent" :config="providerPayload" @success="successHandler" v-bind="providerPayload" />
		<div v-else>Loading...</div>
		<!-- <div v-if="true">
			<h1>Stripe</h1>
			<StripeComponent :sku="sku" @success="successHandler" />
		</div>
		<div v-if="true">
			<h1>Adyen</h1>
			<AdyenCard :sku="sku" @success="successHandler" />
		</div> -->
	</div>
</template>
<script>
	import AdyenCard from '../components/AdyenCard.vue'
	import StripeComponent from '../components/StripeComponent.vue'
	export default {
		components: {
			AdyenCard,
			StripeComponent,
		},
		mounted() {
			this.sendHeight()

			this.resizeObserver = new ResizeObserver(() => {
				this.sendHeight()
			})

			this.resizeObserver.observe(this.$el)

			window.addEventListener('message', event => {
				try {
					const payload = JSON.parse(event.data)
					if (payload.type === 'payments-setup') {
						console.log('Payload', payload)
						const payloadData = payload.data
						if (payloadData.stripe !== undefined) {
							this.provider = 'stripe'
							this.providerPayload = payloadData.stripe
						} else if (payloadData.adyen !== undefined) {
							this.provider = 'adyen'
							this.providerPayload = payloadData.adyen
						}
					}
					this.tick += 1
				} catch (error) {
					// console.error('Error parsing message', error)
				}
			})
		},
		beforeUnmount() {
			window.removeEventListener('message', this.handleMessage)
			this.resizeObserver.disconnect()
		},
		data() {
			return {
				provider: null,
				providerPayload: null,
				tick: 0,
				providers: {
					stripe: StripeComponent,
					adyen: AdyenCard,
				},
			}
		},
		methods: {
			successHandler() {
				console.log('Checkout successful')
				const message = { type: 'payment-status-update', status: 'success' }
				window.parent.postMessage(message, '*') // Replace '*' with your parent window's origin
			},
			sendHeight() {
				const height = document.body.scrollHeight
				const message = { type: 'contentHeight', height }
				window.parent.postMessage(message, '*')
			},
		},
		updated() {
			this.sendHeight()
		},
		computed: {
			providerComponent() {
				const component = this.providers[this.provider]
				return component
			},
		},
	}
</script>

<style lang="scss" scoped></style>
