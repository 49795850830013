<template>
	<div>
		<div class="mb-10">
			<div class="flex justify-between">
				<h1 class="text-2xl font-bold">Bank account</h1>
				<span class="cursor-pointer opacity-0" @click="useDefaults"> Use Defaults </span>
			</div>
			<div class="">Where should we send your earnings?</div>
		</div>
		<div class="mb-10">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="mobile_number"> Account details </label>
			<input class="form-input" type="text" placeholder="Account Holder Name" v-model="localData.bank_account.account_holder_name" />
			<p class="form-input-error" v-if="errors.account_holder_name">Please double-check this field!</p>
		</div>
		<div class="mb-10">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="mobile_number"> Routing number </label>
			<input class="form-input" id="mobile_number" type="text" placeholder="110000000" v-model="localData.bank_account.routing_number" />
			<p class="form-input-error" v-if="errors.routing_number">Please double-check this field!</p>
		</div>
		<div class="mb-10">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="email"> Account number </label>
			<input class="form-input" id="date_of_birth" type="text" placeholder="000123456789" v-model="localData.bank_account.account_number" />
			<p class="form-input-error" v-if="errors.account_number">Please double-check this field!</p>
		</div>
	</div>
</template>

<script setup>
	import { defineProps, defineEmits, onMounted } from 'vue'
	import { useOnboarding } from './useOnboarding'

	onMounted(() => {
		if (!localData.bank_account.account_holder_name) {
			generateAccountHolder()
		}
		if (!localData.bank_account.country) {
			generateAccountCountry()
		}
	})

	const generateAccountHolder = () => {
		if (!!localData.individual.first_name && !!localData.individual.last_name) {
			localData.bank_account.account_holder_name = `${localData.individual.first_name} ${localData.individual.last_name}`
		}
	}

	function generateAccountCountry() {
		if (localData.individual.address.country) {
			localData.bank_account.country = localData.individual.address.country
		}
		// Override for POC:
		localData.bank_account.country = 'US'
		return
	}

	const props = defineProps({
		onboardingData: {
			type: Object,
			required: true,
		},
		errors: {
			type: Object,
			required: true,
		},
		rules: {
			type: Object,
			required: true,
		},
	})

	const useDefaults = () => {
		localData.bank_account.account_holder_name = 'Jane Doe'
		localData.bank_account.country = 'US'
		localData.bank_account.currency = 'usd'
		localData.bank_account.routing_number = '110000000'
		localData.bank_account.account_number = '000123456789'
	}

	const emits = defineEmits(['update:onboardingData'])

	const { localData } = useOnboarding(props, emits)
</script>

<style lang="scss" scoped></style>
