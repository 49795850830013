<template>
	<div class="flex justify-center">
		<div class="p-3 max-w-lg flex-auto bg-white">
			<div class="border-solid p-5" v-if="mounted">
				<div v-if="account">
					<component
						:is="activeComponent.component"
						:v="v$"
						@next="nextStep"
						@prev="prevStep"
						@update:onboardingData="updateData"
						@update:businessType="changeBusinessType"
						@go="goToStep"
						:onboardingData="onboardingData"
						:rules="rules"
						:errors="formErrors"
						:key="tick"
					></component>
					<div class="mt-12">
						<button
							@click="nextStep"
							class="bg-indigo-500 text-white hover:bg-indigo-600 py-2 px-5 rounded w-full"
							:class="{ 'opacity-50': disabledCta }"
							:disabled="disabledCta"
						>
							<span v-if="!loading"> {{ steps[step].ctaText ?? 'Continue' }} </span>
							<span v-else>Loading...</span>
						</button>
					</div>
					<div v-if="!['review', 'tos'].includes(activeComponent.key)">
						<button @click="skipStep" class="bg-transparent text-indigo-500 font-semibold hover:text-indigo-600 py-2 px-4 w-full">
							Save for later
						</button>
					</div>
				</div>
				<div v-else>It looks like we're missing a valid session ID</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import OnboardingPersonal from '../components/onboarding/OnboardingPersonal.vue'
	import OnboardingReview from '../components/onboarding/OnboardingReview.vue'
	import OnboardingBanking from '../components/onboarding/OnboardingBanking.vue'
	import OnboardingTos from '../components/onboarding/OnboardingTos.vue'
	// import OnboardingCompany from '../components/onboarding/OnboardingCompany.vue'
	import * as Sentry from '@sentry/vue'
	import axios from 'axios'
	import { required, minLength, maxLength } from '@vuelidate/validators'
	import { ref, computed, onMounted, watch } from 'vue'
	import { useRoute } from 'vue-router'

	onMounted(async () => {
		await fetchSession()
		if (account.value) {
			await fetchIndividual()
			await fetchBankAccount()
			const flag = true
			if (flag) await fetchCompany()
		}
		updateData()
		steps = generateSteps()
		mounted.value = true
		if (isReadyForReview()) {
			goToStep('review')
		}
	})

	const mounted = ref(false)
	const step = ref(0)
	const loading = ref(false)
	const route = useRoute()
	const account = ref(null)
	const bankAccountToken = ref(null)
	const individualToken = ref(null)
	const companyToken = ref(null)
	const formErrors = ref({})
	const tick = ref(0)

	var steps = []

	const _steps = [
		{
			component: OnboardingTos,
			key: 'tos',
			ctaText: 'I agree',
		},
		{
			component: OnboardingPersonal,
			key: 'individual',
		},
		{
			component: OnboardingBanking,
			key: 'bank_account',
		},
		{
			component: OnboardingReview,
			key: 'review',
			ctaText: "I'm done!",
		},
	]

	function generateSteps() {
		return _steps.filter(step => {
			// Skip TOS step if already accepted
			if (step.key === 'tos' && !onboardingData.value.requirements.includes('tos')) {
				return false
			}
			return true
		})
	}

	watch(
		() => route.query.step,
		key => {
			if (key) {
				goToStep(key)
			}
		},
		{ immediate: true }
	)

	function goToStep(key) {
		const stepIndex = steps.findIndex(step => step.key === key)
		if (stepIndex !== -1) {
			step.value = stepIndex
		} else {
			console.error('Step not found: ', key)
		}
	}

	async function fetchSession() {
		// Fetch session data
		const sessionId = route.params.session_id
		try {
			const response = await axios.get(`/api/v1/accounts/onboarding/${sessionId}`)
			const jwt = response.data.jwt
			account.value = response.data.account

			Sentry.setUser({ id: account.value })
			// Set default axios headers
			axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`
			axios.defaults.headers.common['Account'] = account.value

			onboardingData.value.requirements = response.data.requirements || []
			onboardingData.value.capabilities = response.data.capabilities || []
			onboardingData.value.tos = !onboardingData.value.requirements.includes('tos')
			// console.log('Session data', response.data)
			// onboardingData.value.business_profile = { ...onboardingData.value.business_proifile, ...response.data.account_data.business_profile }
		} catch (error) {
			console.error(error)
			console.error('Error fetching session data')
		}
	}

	const onboardingData = ref({
		business_profile: {
			url: 'https://stan.store/pupulated-via-api',
			name: 'Populated via API',
			mcc: '5815',
			type: 'individual',
		},
		individual: {
			email: null,
			phone: null,
			first_name: null,
			last_name: null,
			dob: {
				day: null,
				month: null,
				year: null,
			},
			address: {
				line1: null,
				line2: null,
				city: null,
				state: null,
				postal_code: null,
				country: 'US',
			},
			ssn: null,
			preview: {},
		},
		bank_account: {
			account_holder_name: '',
			account_holder_type: 'individual',
			country: 'US',
			currency: 'USD',
			routing_number: '',
			account_number: '',
			preview: {},
		},
		company: {
			name: '',
			tax_id: '',
			preview: {},
		},
		tos: false,
		requirements: [],
		capabilities: [],
	})

	function isReadyForReview() {
		return !!onboardingData.value.tos // Start with review if TOS is already accepted
	}

	async function submit(postMessage=true) {
		try {
			loading.value = true
			await axios.post(`/api/v1/accounts/${account.value}/tos`, onboardingData.value)
			console.log('Account created')
			onboardingData.value.tos = false
			if (window.opener && postMessage) window.opener.postMessage('submitSuccess', '*')
			return true
		} catch (error) {
			console.error(error)
			console.log('Error creating account')
		} finally {
			loading.value = false
		}
	}

	async function fetchBankAccount() {
		try {
			const response = await axios.get(`/api/v1/accounts/${account.value}/bank_account`)
			onboardingData.value.bank_account = { ...onboardingData.value.bank_account, ...response.data }
			if (response.data.token) {
				bankAccountToken.value = response.data.token
				onboardingData.value.bank_account.token = response.data.token
			}
		} catch (error) {
			console.log('Error fetching bank account')
		}
	}

	async function fetchCompany() {
		try {
			const response = await axios.get(`/api/v1/accounts/${account.value}/company`)
			onboardingData.value.company = { ...onboardingData.value.company, ...response.data }
			onboardingData.value.company = { ...onboardingData.value.company, ...response.data.preview }
			if (response.data.token) {
				companyToken.value = response.data.token
				onboardingData.value.company.token = response.data.token
			}
		} catch (error) {
			console.log('Error fetching company information')
		}
	}

	async function fetchIndividual() {
		try {
			const response = await axios.get(`/api/v1/accounts/${account.value}/individual`)
			onboardingData.value.individual = { ...onboardingData.value.individual, ...response.data }
			onboardingData.value.individual = { ...onboardingData.value.individual, ...response.data.preview }
			if (response.data.token) {
				individualToken.value = response.data.token
				onboardingData.value.individual.token = response.data.token
			}
		} catch (error) {
			console.error(error)
			console.log('Error fetching individual: ', error)
		}
	}

	async function saveBankAccount() {
		try {
			loading.value = true
			const bankAccountData = onboardingData.value.bank_account
			const response = await axios.post('/api/v1/accounts/token/bank_account', bankAccountData)
			bankAccountToken.value = response.data.token
			onboardingData.value.bank_account.token = response.data.token
			tick.value += 1
			return true
		} catch (error) {
			console.log('Error creating bank account')
			console.log(error)
			if (error.response.data.errors) {
				formErrors.value = error.response.data.errors
			}
		} finally {
			loading.value = false
		}
	}

	async function saveIndividual() {
		try {
			loading.value = true
			const individualData = onboardingData.value.individual
			const response = await axios.post('/api/v1/accounts/token/individual', individualData)
			individualToken.value = response.data.token
			onboardingData.value.individual.token = response.data.token
			return true
		} catch (error) {
			console.log('Error creating Individual token')
			if (error.response.data.errors) {
				formErrors.value = error.response.data.errors
			}
		} finally {
			loading.value = false
		}
	}

	async function saveCompany() {
		try {
			loading.value = true
			const companyData = onboardingData.value.company
			const response = await axios.post('/api/v1/accounts/token/company', companyData)
			companyToken.value = response.data.token
			onboardingData.value.company.token = response.data.token
			return true
		} catch (error) {
			console.log('Error creating Company token')
			if (error.response.data.errors) {
				formErrors.value = error.response.data.errors
			}
		} finally {
			loading.value = false
		}
	}

	async function changeBusinessType(type) {
		const payload = { business_profile: { type } }
		try {
			await axios.patch(`/api/v1/accounts/${account.value}`, payload)
			onboardingData.value.business_profile.type = type
		} catch (error) {
			console.log('Error changing business type')
			console.error(error)
		}
	}

	const rules = {
		onboardingData: {
			bank_account: {
				account_holder_name: {
					required,
					minLength: minLength(1),
					maxLength: maxLength(100),
				},
				routing_number: {
					required,
					minLength: minLength(9),
					maxLength: maxLength(9),
				},
				account_number: {
					required,
					minLength: minLength(6),
					maxLength: maxLength(17),
				},
			},
			individual: {
				phone: {
					required,
				},
				email: {
					required,
				},
			},
		},
	}

	import useVuelidate from '@vuelidate/core'
	const v$ = useVuelidate(rules, { onboardingData: onboardingData.value })

	const updateData = data => {
		onboardingData.value = { ...onboardingData.value, ...data }
	}

	function skipStep() {
		step.value = Math.min(step.value + 1, steps.length - 1)
	}

	const nextStep = async () => {
		var success = true
		if (activeComponent.value.key === 'bank_account') {
			success = await saveBankAccount()
		} else if (activeComponent.value.key === 'individual') {
			success = await saveIndividual()
		} else if (activeComponent.value.key === 'company') {
			success = await saveCompany()
		} else if (activeComponent.value.key === 'tos') {
			success = await submit(false)
		}

		if (!success) {
			console.log('Error saving data')
			return
		}

		if (step.value === steps.length - 1) {
			submit()
			return
		} else {
			step.value = Math.min(step.value + 1, steps.length - 1)
		}
	}
	const prevStep = () => {
		step.value = Math.max(step.value - 1, 0)
	}
	const activeComponent = computed(() => steps[step.value])
	const disabledCta = computed(() => {
		return loading.value
	})
</script>

<style lang="scss" scoped></style>
