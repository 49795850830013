<template>
	<div class="">
		<h1>iframe Demo</h1>
		<div class="iframe-container bg-slate-50 align-items-center">
			<iframe src="http://localhost:8080" frameborder="0" id="checkoutFrame"></iframe>
		</div>
		<h1 class="text-center">Section below iframe</h1>
		<p class="text-center mt-3">{{ lastIframeMessage }}</p>
	</div>
</template>

<script setup>
	import axios from 'axios'
	import { onMounted, ref } from 'vue'

	const lastIframeMessage = ref(null)

	onMounted(() => {
		window.addEventListener('message', event => {
			if (event.data.type === 'payment-status-update' && event.data.status === 'success') {
				lastIframeMessage.value = 'Payment successful'
			}
		})

		window.addEventListener('message', event => {
			if (event.data.type === 'contentHeight') {
				const iframe = document.getElementById('checkoutFrame')
				iframe.style['min-height'] = `${event.data.height}px`
			}
		})

		createSession()
	})

	const postToFrame = (type, data) => {
		const iframe = document.getElementById('checkoutFrame')
		const payload = { type, data, timestamp: new Date().toISOString() }
		if (iframe) {
			iframe.contentWindow.postMessage(JSON.stringify(payload), '*')
		} else {
			console.error('iframe not found')
		}
	}

	const createSession = () => {
		const publishableKey = 'pk_alpha'
		const headers = {
			Authorization: `Bearer ${publishableKey}`,
			Account: 'acct_b1ad50836af84a', // TODO: Dynamically populate this
		}
		axios
			.post('/api/v1/checkout/session', {}, { headers })
			.then(response => {
				postToFrame('payments-setup', response.data)
			})
			.catch(error => {
				console.error(error)
			})
	}
</script>

<style lang="scss" scoped>
	.iframe-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		// width: 100%;
		height: 100%;
	}

	h1 {
		text-align: center;
		margin: 0;
		padding: 1rem;
		// background-color: #f8f8f8;
		border-bottom: 1px solid #ccc;
		border-top: 1px solid #ccc;
		z-index: 1;
	}

	iframe {
		flex: 1;
		border: none;
		width: 100%;
		height: 100%;
	}
</style>
