<template>
	<div>
		<div class="mb-10">
			<div class="flex justify-between">
				<h1 class="text-2xl font-bold">Before we dive in</h1>
			</div>
			<div class="">Let's confirm</div>
		</div>
		<div class="">
			<div class="flex">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="green" class="size-6 mr-1">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
					/>
				</svg>
				<span class="mr-1 font-slate-500">Do you agree to Stripe's</span>
				<a target="_blank" class="cursor-pointer text-indigo-500 underline" href="https://stripe.com/legal/ssa">Terms of Service</a>
				<span>?</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'OnboardingPersonal',
	}
</script>

<script setup></script>

<style lang="scss" scoped></style>
