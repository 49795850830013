<template>
	<div>
		<!-- <div v-if="error" class="error">{{ error }}</div> -->
		<div id="adyen-container">
			<div id="adyen-component"></div>
			<!-- <button @click="submitPayment">Pay</button> -->
		</div>
		<div></div>
	</div>
</template>

<script>
	import { ref, onMounted } from 'vue'
	import AdyenCheckout from '@adyen/adyen-web'
	import '@adyen/adyen-web/dist/adyen.css'
	import axios from 'axios'

	export default {
		emits: ['success'], // Declare the emitted event
		setup(props, { emit }) {
			const checkout = ref(null)
			const error = ref(null)
			const dropinComponent = ref(null)
			const sessionData = ref(null)
			// const paymentRefused = ref(false)

			const prefilledCardData = {
				holderName: 'John Doe',
				number: '4111111145551142',
				expiryMonth: '03',
				expiryYear: '2030',
				cvc: '737',
			}
			console.log('testCardData: ', prefilledCardData)

			onMounted(async () => {
				try {
					// Get the Adyen configuration and payment methods from your server
					const config = await getAdyenConfig()
					const paymentSession = await getPaymentSession()

					checkout.value = await AdyenCheckout({
						environment: 'test', // Change to 'live' for production
						clientKey: config.clientKey,
						session: paymentSession,
						onSubmit: (state, component) => {
							handlePayment(state.data, component)
						},
						onPaymentCompleted: (result, component) => {
							handlePaymentCompleted(result, component)
						},
						onError: error => {
							console.error(error)
						},
					})

					// cardComponent.value = checkout.value.create('card').mount('#adyen-component')
					dropinComponent.value = checkout.value.create('dropin').mount('#adyen-component')
				} catch (err) {
					error.value = 'Failed to load payment methods'
					console.error(err)
				}
			})

			const getPaymentSession = async () => {
				const response = await axios.get('/api/v1/adyen/session')
				sessionData.value = response.data
				return response.data
			}

			const getAdyenConfig = async () => {
				const response = await axios.get('/api/v1/adyen/config')
				return response.data
			}

			const handlePayment = async (paymentData, component) => {
				const payload = {
					paymentData: paymentData,
					sessionData: sessionData.value,
				}

				const response = await axios.post('/api/v1/adyen/payments', payload, {
					headers: {
						'Content-Type': 'application/json',
					},
				})
				const result = response.data
				if (result.action) {
					checkout.value.handleAction(result.action)
				} else {
					handlePaymentCompleted(result, component)
				}
			}

			const submitPayment = () => {
				if (dropinComponent.value) {
					dropinComponent.value.submit()
				}
			}

			const handlePaymentCompleted = (result, component) => {
				if (result.resultCode === 'Authorised') {
					console.log('Payment successful', result)
					component.setStatus('success')
					emit('success')
				} else {
					console.log('Payment failed', result)
					component.setStatus('fail')
				}
			}

			return {
				checkout,
				dropinComponent,
				error,
				submitPayment,
			}
		},
	}
</script>

<style scoped>
	#adyen-component {
		width: 100%;
		max-width: 400px;
		margin: auto;
	}
	.error {
		color: red;
		text-align: center;
		margin-top: 20px;
	}
	button {
		display: block;
		width: 100%;
		padding: 10px;
		background-color: #0070ba;
		color: white;
		border: none;
		border-radius: 5px;
		cursor: pointer;
		margin-top: 20px;
	}
	button:hover {
		background-color: #005999;
	}
</style>
