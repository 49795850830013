import './assets/styles.css'

import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router' // Import the router
import * as Sentry from '@sentry/vue'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

const environment = process.env.VUE_APP_ENVIRONMENT || 'local'

const app = createApp(App)

Sentry.init({
	app,
	dsn: 'https://5f719b7d3ad21ada46f68b1f664cd668@o679650.ingest.us.sentry.io/4507333699502081',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	environment: environment,
	tracesSampleRate: 1.0,
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 1.0,
	replaysOnErrorSampleRate: 1.0,
})

app.use(router).mount('#app')
