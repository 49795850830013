<template>
	<div>
		<form id="payment-form" @submit.prevent="handleSubmit">
			<div id="payment-element">
				<!--Stripe.js injects the Payment Element-->
			</div>
			<button id="submit" :disabled="isProcessing">
				<div class="spinner hidden" id="spinner"></div>
				<span id="button-text">{{ isProcessing ? 'Processing...' : 'Pay now' }}</span>
			</button>
			<div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
			<div v-if="successMessage" class="success-message">{{ successMessage }}</div>
		</form>
	</div>
</template>

<script>
	// import axios from 'axios'
	import { ref, onMounted } from 'vue'
	import { loadStripe } from '@stripe/stripe-js'

	export default {
		// name: 'StripePayment',
		emits: ['success'], // Declare the emitted event
		props: {
			publishable_key: {
				type: String,
				required: true,
			},
			clientSecret: {
				type: String,
				required: true,
			},
			account: {
				type: String,
				required: true,
			},
		},
		setup(props, { emit }) {
			const stripe = ref(null)
			const elements = ref(null)
			const paymentElement = ref(null)
			const isProcessing = ref(false)
			const errorMessage = ref(null)
			const successMessage = ref(null)
			// const session = ref(null)

			// const fetchClientSecret = async () => {
			// 	const payload = {}
			// 	const response = await axios.post('/api/v1/stripe/payments', payload)
			// 	session.value = response.data.clientSecret
			// 	return response.data.clientSecret
			// }

			onMounted(async () => {
				const pk = props.publishable_key // 'pk_test_PRgrf5vM5DzFgfnoHkyCETm500Rj5fj10N'
				stripe.value = await loadStripe(pk)
				const clientSecret = props.clientSecret // await fetchClientSecret()

				const appearance = {
					theme: 'stripe',
				}

				elements.value = stripe.value.elements({ appearance, clientSecret })
				paymentElement.value = elements.value.create('payment')
				paymentElement.value.mount('#payment-element')
			})

			const handleSubmit = async () => {
				isProcessing.value = true
				errorMessage.value = null

				console.log('Submitting payment')

				const { error, paymentIntent } = await stripe.value.confirmPayment({
					elements: elements.value,
					confirmParams: {
						return_url: 'http://localhost:3000/checkout',
					},
					redirect: 'if_required',
				})

				if (error) {
					console.log('Error: ', error)
					errorMessage.value = error.message
				} else if (paymentIntent && paymentIntent.status === 'succeeded') {
					emit('success')
					successMessage.value = 'Payment successful!'
					console.log('Payment successful')
				}

				isProcessing.value = false
			}

			return {
				paymentElement,
				isProcessing,
				errorMessage,
				handleSubmit,
				successMessage,
			}
		},
	}
</script>

<style scoped>
	#error-message {
		color: red;
		margin-top: 1rem;
	}

	/* Variables */
	* {
		box-sizing: border-box;
	}

	body {
		font-family: -apple-system, BlinkMacSystemFont, sans-serif;
		font-size: 16px;
		-webkit-font-smoothing: antialiased;
		display: flex;
		justify-content: center;
		align-content: center;
		height: 100vh;
		width: 100vw;
	}

	form {
		width: 30vw;
		min-width: 500px;
		align-self: center;
		box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
		border-radius: 7px;
		padding: 40px;
	}

	.hidden {
		display: none;
	}

	#payment-message {
		color: rgb(105, 115, 134);
		font-size: 16px;
		line-height: 20px;
		padding-top: 12px;
		text-align: center;
	}

	#payment-element {
		margin-bottom: 24px;
	}

	/* Buttons and links */
	button {
		background: #5469d4;
		font-family: Arial, sans-serif;
		color: #ffffff;
		border-radius: 4px;
		border: 0;
		padding: 12px 16px;
		font-size: 16px;
		font-weight: 600;
		cursor: pointer;
		display: block;
		transition: all 0.2s ease;
		box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
		width: 100%;
	}
	button:hover {
		filter: contrast(115%);
	}
	button:disabled {
		opacity: 0.5;
		cursor: default;
	}

	/* spinner/processing state, errors */
	.spinner,
	.spinner:before,
	.spinner:after {
		border-radius: 50%;
	}
	.spinner {
		color: #ffffff;
		font-size: 22px;
		text-indent: -99999px;
		margin: 0px auto;
		position: relative;
		width: 20px;
		height: 20px;
		box-shadow: inset 0 0 0 2px;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
	}
	.spinner:before,
	.spinner:after {
		position: absolute;
		content: '';
	}
	.spinner:before {
		width: 10.4px;
		height: 20.4px;
		background: #5469d4;
		border-radius: 20.4px 0 0 20.4px;
		top: -0.2px;
		left: -0.2px;
		-webkit-transform-origin: 10.4px 10.2px;
		transform-origin: 10.4px 10.2px;
		-webkit-animation: loading 2s infinite ease 1.5s;
		animation: loading 2s infinite ease 1.5s;
	}
	.spinner:after {
		width: 10.4px;
		height: 10.2px;
		background: #5469d4;
		border-radius: 0 10.2px 10.2px 0;
		top: -0.1px;
		left: 10.2px;
		-webkit-transform-origin: 0px 10.2px;
		transform-origin: 0px 10.2px;
		-webkit-animation: loading 2s infinite ease;
		animation: loading 2s infinite ease;
	}

	@-webkit-keyframes loading {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes loading {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	@media only screen and (max-width: 600px) {
		form {
			width: 80vw;
			min-width: initial;
		}
	}
</style>
