<template>
	<div>
		<div class="mb-10">
			<div class="flex justify-between">
				<h1 class="text-2xl font-bold">Personal details</h1>
				<span class="cursor-pointer opacity-0" @click="useDefaults"> Use Defaults </span>
			</div>
			<div class="">Let's verify your identity and keep your account safe.</div>
		</div>
		<div class="mb-10">
			<label class="flex justify-start items-center text-gray-700 text-sm font-bold mb-2" for="first_name">
				<span class="mr-3">Your legal name</span>
				<div v-if="requiresVerification" class="tag-red">Please confirm</div>
			</label>
			<input class="form-input" id="first_name" type="text" placeholder="First name" v-model="localData.individual.first_name" />
			<p class="form-input-error" v-if="errors.first_name">Please double-check this field!</p>

			<input class="form-input mt-2" id="last_name" type="text" placeholder="Last name" v-model="localData.individual.last_name" />
			<p class="form-input-error" v-if="errors.last_name">Please double-check this field!</p>
		</div>
		<div class="mb-10">
			<label class="flex justify-start items-center text-gray-700 text-sm font-bold mb-2" for="first_name">
				<span class="mr-3">Date of birth</span>
				<div v-if="requiresVerification" class="tag-red">Please confirm</div>
			</label>
			<div class="grid grid-cols-3 gap-2">
				<input class="form-input" type="number" placeholder="Month (MM)" v-model="localData.individual.dob.month" />
				<input class="form-input" type="number" placeholder="Day (DD)" v-model="localData.individual.dob.day" />
				<input class="form-input" type="number" placeholder="Year (YYYY)" v-model="localData.individual.dob.year" />
			</div>
			<p class="form-input-error" v-if="errors.dob?.day || errors.dob?.month || errors.dob?.year">Please double-check this field!</p>
		</div>
		<div class="mb-10">
			<label class="block text-gray-700 text-sm font-bold mb-2" for="address">Home address</label>
			<input class="form-input" id="address" type="text" placeholder="Street address" v-model="localData.individual.address.line1" />
			<p class="form-input-error" v-if="errors.address?.line1">Please double-check this field!</p>

			<input class="form-input mt-2" type="text" placeholder="Apartment, unit, or other" v-model="localData.individual.address.line2" />
			<p class="form-input-error" v-if="errors.address?.line2">Please double-check this field!</p>

			<input class="form-input mt-2" id="city" type="text" placeholder="City" v-model="localData.individual.address.city" />
			<p class="form-input-error" v-if="errors.address?.city">Please double-check this field!</p>

			<input class="form-input mt-2" id="state" type="text" placeholder="State" v-model="localData.individual.address.state" />
			<p class="form-input-error" v-if="errors.address?.state">Please double-check this field!</p>

			<input class="form-input mt-2" id="zip" type="text" placeholder="Zip code" v-model="localData.individual.address.postal_code" />
			<p class="form-input-error" v-if="errors.address?.postal_code">Please double-check this field!</p>
		</div>
		<div class="mb-10">
			<label class="flex justify-start items-center text-gray-700 text-sm font-bold mb-2" for="first_name">
				<span class="mr-3">Social Security number</span>
				<div v-if="requiresVerification || requiresIndividualId" class="tag-red">Please confirm</div>
			</label>
			<input class="form-input" id="ssn" type="text" :placeholder="ssnPlaceholder" v-model="localData.individual.ssn" />
			<p class="form-input-error" v-if="errors.ssn">Please double-check this field!</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'OnboardingPersonal',
	}
</script>

<script setup>
	import { defineProps, defineEmits, computed } from 'vue'
	import { useOnboarding } from './useOnboarding'

	const props = defineProps({
		onboardingData: {
			type: Object,
			required: true,
		},
		errors: {
			type: Object,
			required: false,
		},
	})

	const useDefaults = () => {
		localData.individual.first_name = 'Jane'
		localData.individual.last_name = 'Doe'
		localData.individual.dob.day = 27
		localData.individual.dob.month = 10
		localData.individual.dob.year = 2001
		localData.individual.address.line1 = 'address_full_match'
		localData.individual.address.line2 = 'Apt 1'
		localData.individual.address.city = 'Anytown'
		localData.individual.address.state = 'NY'
		localData.individual.address.postal_code = '12345'
		localData.individual.ssn = '000000000'
	}

	const ssnPlaceholder = computed(() => {
		const ssnLast4 = localData.individual?.preview?.ssn_last4
		return ssnLast4 ? `*****${ssnLast4}` : '123-45-6789'
	})

	const emits = defineEmits(['update:onboardingData'])
	const { localData, requiresVerification, requiresIndividualId } = useOnboarding(props, emits)
</script>

<style lang="scss" scoped></style>
