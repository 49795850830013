<template>
	<div>
		<h1 class="text-2xl font-bold">Review and submit</h1>
		<div>Take a moment to review your information.</div>
		<div class="mt-3" v-if="isPaymentsEnabled">
			<IndividualVerificationSuccess />
		</div>
		<div class="mt-10 mb-10">
			<div class="flex justify-between my-1">
				<div class="flex justify-start">
					<div class="font-bold">Personal details</div>
					<div v-if="!onboardingData.individual.token || requiresVerification || requiresIndividualId" class="tag-red mx-3">Required</div>
					<div v-else class="tag-green mx-3">Complete</div>
				</div>
				<div class="text-indigo-500 underline cursor-pointer" @click="editIndividual">Edit</div>
			</div>
			<div v-if="requiresVerification || requiresIndividualId">
				<IndividalVerificationAlert class="mt-3" />
			</div>
			<div class="text-slate-500" v-if="!!onboardingData.individual.token">
				<div>
					<div>
						<span class="mr-1">{{ onboardingData?.individual?.first_name }}</span>
						<span>{{ onboardingData.individual?.last_name }}</span>
					</div>
					<div>
						<span>{{ onboardingData?.individual?.email }}</span>
					</div>
					<div>
						<span>{{ addressPreview }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="mb-10">
			<div class="flex justify-between">
				<div class="flex justify-start my-1">
					<div class="font-bold">Payout details</div>
					<div v-if="!onboardingData.bank_account.token" class="mx-3 tag-red">Required</div>
					<div v-else class="mx-3 tag-green">Complete</div>
				</div>
				<div class="text-indigo-500 underline cursor-pointer" @click="editBankDetails">Edit</div>
			</div>
			<div class="text-slate-500" v-if="!!onboardingData.bank_account.token">
				<div>
					<span>Bank Account ***{{ previewBankAccount }}</span>
				</div>
			</div>
		</div>
		<!-- <div class="mb-10">
			<div class="flex justify-between">
				<div class="flex justify-start">
					<div class="font-bold">Company (optional)</div>
					<div v-if="!onboardingData.company.token && companyToggle" class="tag-red mx-2">Required</div>
				</div>
				<label class="inline-flex items-center cursor-pointer">
					<input type="checkbox" v-model="companyToggle" class="sr-only peer" id="company-toggle" @click="updateCompanyType" />
					<div
						class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
					></div>
				</label>
			</div>
			<div class="text-slate-500">
				<div class="flex justify-between pt-2">
					<span>{{ previewCompany }}</span>
					<div class="text-indigo-500 underline cursor-pointer" @click="editCompanyDetails">Edit</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script setup>
	import { defineProps, defineEmits, computed, ref, onMounted, watch } from 'vue'
	import { useOnboarding } from './useOnboarding'
	import IndividalVerificationAlert from './IndividualVerificationAlert.vue'
	import IndividualVerificationSuccess from './IndividualVerificationSuccess.vue'

	const props = defineProps({
		onboardingData: {
			type: Object,
			required: true,
		},
		rules: {
			type: Object,
			required: true,
		},
	})

	onMounted(() => {
		if (props.onboardingData?.business_profile?.type === 'company') {
			companyToggle.value = true
		}
	})

	const emits = defineEmits(['update:onboardingData', 'update:businessType', 'go', 'tosCheck'])

	const companyToggle = ref(false)
	watch(companyToggle, value => {
		const businessType = value ? 'company' : 'individual'
		emits('update:businessType', businessType)
	})

	function editBankDetails() {
		emits('go', 'bank_account')
	}

	function editIndividual() {
		emits('go', 'individual')
	}

	// function editCompanyDetails() {
	// 	emits('go', 'company')
	// }

	const addressPreview = computed(() => {
		const address = [
			props.onboardingData?.individual?.address?.line2,
			props.onboardingData?.individual?.address?.line1,
			props.onboardingData?.individual?.address?.city,
			props.onboardingData?.individual?.address?.state,
			props.onboardingData?.individual?.address?.country,
			props.onboardingData?.individual?.address?.postal_code,
		]
			.filter(Boolean)
			.join(', ')
		return address
	})

	const isPaymentsEnabled = computed(() => {
		return props.onboardingData?.capabilities?.includes('card_payments')
	})

	const previewBankAccount = computed(() => {
		return props.onboardingData?.bank_account?.account_number?.slice(-4) || props.onboardingData?.bank_account?.preview?.last4
	})

	// const previewCompany = computed(() => {
	// 	return props.onboardingData?.company?.name || 'Process payments as an LLC or Corporation'
	// })

	// function updateCompanyType() {
	// 	if (localData.business_profile.type === 'individual') {
	// 		localData.business_profile.type = 'company'
	// 	} else {
	// 		localData.business_profile.type = 'individual'
	// 	}
	// }

	const { requiresVerification, requiresIndividualId } = useOnboarding(props, emits)
</script>

<style lang="scss" scoped></style>
