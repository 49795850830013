<template>
	<div class="flex items-top p-4 mb-4 text-sm text-green-700 rounded-lg bg-green-100" role="alert">
		<svg
			class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
			aria-hidden="true"
			xmlns="http://www.w3.org/2000/svg"
			fill="currentColor"
			viewBox="0 0 20 20"
		>
			<path
				d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
			/>
		</svg>
		<span class="sr-only">Info</span>
		<div>
			<span class="font-medium"><b>You're all set!</b></span
			><br /><span>You can now process payments.</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				message: 'To continue processing payments, please update your personal information to reflect your government-issued ID.',
			}
		},
	}
</script>

<style lang="scss" scoped></style>
